import {ReactElement} from 'react';
import styled from 'styled-components';
import {colors, mediaQueries} from '@theme/lib';
import {QuoteItemFragment} from '@components/SubParagraphs/QuoteItem/__generated__/QuoteItemFragment';

import quote from './assets/quote.svg';
import circle from './assets/light-green-circle.svg';

const QuoteItemWrapper = styled.div`
  background: ${colors.panther};
  border-radius: ${props => props.theme.config.componentRadius};
  grid-column: 1 / span 4;
  overflow: hidden;
  height: 267px;
  width: 100%;

  ${mediaQueries.sm} {
    height: 429px;
  }

  ${mediaQueries.md} {
    height: 459px;
  }

  ${mediaQueries.lg} {
    height: 510px;
  }

  .wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    color: ${colors.white};
    border-radius: ${props => props.theme.config.componentRadius};
    overflow: hidden;
    display: grid;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;

    &::after {
      background-image: url(${circle});
      background-size: cover;
      background-position: 0px -12px;
      background-repeat: no-repeat;
      content: "";
      width: 100%;
      height: 273%;
      max-width: 140px;
      display: block;
      position: absolute;
      overflow: hidden;
      top: -266px;
      left: 208px;
      transform: rotate(-35deg);
      display: block;
      position: absolute;
      overflow: hidden;
      z-index: 1;

      ${mediaQueries.md} {
        width: 100%;
        height: 232%;
        max-width: 330px;
        top: -225px;
        left: 328px;
        transform: rotate(-60deg);
      }
    }
  }

  .inner-wrapper-block {
    background: ${colors.panther};
    margin: 0 3.75rem;
    padding: 1rem 0;
    position: relative;
    z-index: 2;

    &::before {
      background: url(${quote});
      content: '';
      position: absolute;
      top: -30px;
      left: 0;
      height: 27px;
      width: 34px;
    }
  }

  .content-block {
    p {
      font-size: 1.5rem;
      line-height: 1.65rem;
      font-weight: 400;
    }
  }

  .author-block {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-top: 0.75rem;
  }

  ${mediaQueries.lg} {
    .content-block {
      p {
        font-size: 1.875rem;
        line-height: 2.0625rem;
      }
    }

    .author-block {
      font-size: 1.125rem;
      line-height: 1.2375rem;
    }
  }
`;

export default function QuoteItem({ content, author }: QuoteItemFragment): ReactElement {
  return <QuoteItemWrapper>
    <div className="wrapper">
      <div className="inner-wrapper-block">
        <div className="content-block" dangerouslySetInnerHTML={{ __html: content.processed }}></div>
        {author ? (<div className="author-block">
          - {author}
        </div>) : ''}
      </div>
    </div>
  </QuoteItemWrapper>;
}
