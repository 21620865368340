import {ReactElement} from 'react';
import styled from 'styled-components';
import {buttonTemplate, colors, mediaQueries} from '@theme/lib';
import {CallToActionItemFragment} from "@components/SubParagraphs/CallToActionItem/__generated__/CallToActionItemFragment";
import ImageItem from "@components/SubParagraphs/ImageItem";

const CallToActionWrapper = styled.div`
  border-radius: ${props => props.theme.config.componentRadius};
  background: ${colors.panther};
  grid-column: 1 / span 4;

  height: 267px;

  ${mediaQueries.sm} {
    height: 429px;
  }

  ${mediaQueries.md} {
    height: 459px;
  }

  ${mediaQueries.lg} {
    height: 510px;
  }

  width: 100%;

  text-align: center;

  .wrapper {
    border-radius: ${props => props.theme.config.componentRadius};
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    display: grid;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .content-block {
    color: ${colors.white};
    padding: 3.75rem 2rem;
  }

  .title-block {
    font-size: 1.5rem;
    line-height: 1.65rem;
    font-weight: 400;
  }

  .btn-primary {
    ${buttonTemplate}
    color: ${(props) => props.theme.colors.primary};
    background-color: ${colors.white};
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400;

    &:hover {
      text-decoration: none;
      border-color: ${(props) => props.theme.colors.button.primary.focusBorder};
    }

    ${mediaQueries.lg} {
      line-height: 1.35rem;
      font-size: 1.125rem;
    }
  }

  ${mediaQueries.lg} {
    .content-block {
      color: ${colors.white};
      padding: 3.75rem 5.25rem;
    }

    .title-block {
      font-size: 2.25rem;
      line-height: 2.475rem;
    }
  }
`;

export default function CallToActionItem({ id, title, callToAction, backgroundImage }: CallToActionItemFragment): ReactElement {
  const content = (
    <div className="content-block">
      {title && <div className="title-block">{title}</div>}
      <a href={callToAction.url} target={callToAction.target ? callToAction.target : undefined} className="btn btn-primary">
        {callToAction.title}
      </a>
    </div>
  );
  return backgroundImage
    ? <ImageItem __typename={"ImageItemParagraph"} id={id} image={backgroundImage} content={content}></ImageItem>
    : <CallToActionWrapper><div className="wrapper">{content}</div></CallToActionWrapper>;
}
