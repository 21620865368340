import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import styled from 'styled-components';
import {mediaQueries} from '@theme/lib';
import YouTube, {YouTubeEvent, YouTubeProps} from 'react-youtube';

const VideoWrapper = styled.div`
  background: transparent;
  grid-column: 1 / span 6;
  width: 100%;

  .wrapper {
    iframe {
      border-radius: ${props => props.theme.config.componentRadius};
      height: 267px;

      ${mediaQueries.sm} {
        height: 429px;
      }

      ${mediaQueries.md} {
        height: 459px;
      }

      ${mediaQueries.lg} {
        height: 510px;
      }
    }
  }
`;

interface VideoItemProps {
  id: string
  video: {
    id: string
    url: string
  }
}

export interface VideoItemEventsProps {
  pauseVideo: Function
}

const VideoItem = forwardRef((props: VideoItemProps, ref) => {
  const player = useRef<YouTubeEvent>();

  useImperativeHandle(ref, () => ({
    pauseVideo() {
      if (player.current) {
        player.current.target.pauseVideo();
      }
    },
  }));

  const match = props.video.url.match(/\?v=(.+?)$/);

  if (!match) {
    return <></>;
  }

  const opts: YouTubeProps['opts'] = {
    height: '510px',
    width: '100%',
    playerVars: {
      controls: 2,
      fs: 1,
      iv_load_policy: 3,
      modestbranding: 0,
      rel: 0,
      showinfo: 0,
    },
  };

  const storeEvent = (e: YouTubeEvent) => {
    player.current = e;
  };

  return <VideoWrapper id={`video-item__${props.id}`} className="video-item">
    <div className="wrapper">
      <YouTube videoId={match[1]} opts={opts} onReady={storeEvent}></YouTube>
    </div>
  </VideoWrapper>;
});

VideoItem.displayName = 'VideoItem';

export default VideoItem;
